import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Media, MediaType } from "../../../../interfaces/media";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MediaListWidgetType } from "../media-list/media-list-widget-type";
import { CmnIfPermDirective, Customer, ScopeService } from "hcl-lib";
import { TranslateService } from "@ngx-translate/core";
import { NgIf, TitleCasePipe } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MediaListWidget } from "../media-list/media-list-widget.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslationModule } from "projects/hcl-portal/src/app/translation/translation.module";

@Component({
    selector: 'app-media-picker-dialog',
    templateUrl: './media-picker-dialog.component.html',
    styleUrls: ['./media-picker-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, TitleCasePipe, MatDialogModule, MatTabsModule, MediaListWidget, TranslationModule, MatButtonModule, MatIconModule, CmnIfPermDirective]
})
export class MediaPickerDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  actionLabel!: string
  mediaTypeName!: string
  oneMediaTypeName!: string
  mediaTypeNamePlural!: string
  manyMediaTypeName!: string
  theMediaTypeName!: string
  private readonly MEDIA_TYPE_TRANSLATE_PREFIX = "MEDIA_TYPE.";

  customer!: Customer
  selectedMedia!: Media

  allowedMediaTypes: MediaType[] = []
  allowedPublicMediaTypes: MediaType[] = []
  restrictToAppIds: string[] = []

  showPublicMediasTab!: boolean
  showActivityMediasTab!: boolean

  protected readonly MediaListWidgetType = MediaListWidgetType

  constructor(
    public dialogRef: MatDialogRef<MediaPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scopeService: ScopeService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    if (this.data) {
      if (this.data.actionLabel) {
        this.actionLabel = this.data.actionLabel
      }
      if (this.data.mediaTypes) {
        this.allowedMediaTypes = this.data.mediaTypes
      }
      if (this.data.publicMediaTypes) {
        this.allowedPublicMediaTypes = this.data.publicMediaTypes
      }
      if (this.data.restrictToAppIds) {
        this.restrictToAppIds = this.data.restrictToAppIds
      }
      if (this.data.hasOwnProperty('hidePublicMediasTab')) {
        this.showPublicMediasTab = !this.data.hidePublicMediasTab
      } else {
        this.showPublicMediasTab = true
      }
      if (this.data.hasOwnProperty('hideActivityMediasTab')) {
        this.showActivityMediasTab = !this.data.hideActivityMediasTab
      } else {
        this.showActivityMediasTab = true
      }
    }

    this.subscriptions.add(
      this.scopeService.getScopeCustomer().subscribe(customer => {
        this.customer = customer as Customer
      })
    )

    if (this.allowedMediaTypes && this.allowedMediaTypes.length == 1) {
      this.mediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + this.allowedMediaTypes[0])
      this.oneMediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "ONE." + this.allowedMediaTypes[0])
      this.mediaTypeNamePlural = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "PLURAL." + this.allowedMediaTypes[0])
      this.manyMediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "MANY." + this.allowedMediaTypes[0])
      this.theMediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "THE." + this.allowedMediaTypes[0])
    } else {
      this.mediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "MEDIA")
      this.oneMediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "ONE.MEDIA")
      this.mediaTypeNamePlural = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "PLURAL.MEDIA")
      this.manyMediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "MANY.MEDIA")
      this.theMediaTypeName = this.translateService.instant(this.MEDIA_TYPE_TRANSLATE_PREFIX + "THE.MEDIA")
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  doConfirm(): void {
    this.dialogRef.close(this.selectedMedia)
  }

  onMediaSelected(media: Media): void {
    this.selectedMedia = media
  }

  onMediaImported(media: Media): void {
    this.selectedMedia = media
    this.dialogRef.close(this.selectedMedia)
  }
}
