import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ScopeService} from "hcl-lib";
import {Device, DeviceType} from "projects/hcl-portal/src/app/common/interfaces/device";
import {
  InheritableTemplateDialogComponent
} from "projects/hcl-portal/src/app/common/Layout/Components/widget/template/inheritable-template-dialog/inheritable-template-dialog.component";
import {DeviceService} from "projects/hcl-portal/src/app/common/services/device/device.service";
import {of, Subscription, zip} from "rxjs";
import {filter, map, mergeMap, switchMap} from "rxjs/operators";
import {LegacyTemplateDialogComponent} from "../legacy-template-dialog/legacy-template-dialog.component";
import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {
  CmnIfPluginDirective
} from "projects/hcl-portal/src/app/common/directives/cmn-if-plugin/cmn-if-plugin.directive";
import {TemplateService} from "projects/hcl-portal/src/app/common/services/template/template.service";
import {Template, TemplateType} from "../../../../../interfaces/template";

@Component({
  selector: 'app-template-create-dialog',
  templateUrl: './template-create-dialog.component.html',
  styleUrls: ['./template-create-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, MatButtonModule, TranslateModule, CmnIfPluginDirective]
})
export class TemplateCreateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  canCreateLegacy: boolean = false
  canCreateFullInherited: boolean = false

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<TemplateCreateDialogComponent>,
    private deviceService: DeviceService,
    private templateService: TemplateService,
    private scopeService: ScopeService
  ) {
  }

  ngOnInit(): void {
    this.hasLegacyPermission()
    this.subscriptions.add(
      this.scopeService.hasPermission("scr:template:create:full-inherited")
        .subscribe(canCreateFullInherited => this.canCreateFullInherited = canCreateFullInherited)
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  hasLegacyPermission(): void {
    this.subscriptions.add(
      zip(
        this.deviceService.getDevicesWithPaging(0, 1000).pipe(
          mergeMap(devices => of(devices.data.some((device: Device) => device.type == DeviceType.SCR_RASPBERRY_PI)))
        ),
        this.scopeService.hasPermission('scr:template-legacy:create')
      ).subscribe(([hasLegacyDevice, hasPermission]) => {
        this.canCreateLegacy = hasLegacyDevice || hasPermission
      })
    )
  }

  goToNewTemplate() {
    this.dialogRef.close()
    this.router.navigate(["screenlab", "templates", "create"])
  }

  goToNewPosterMyWallTemplate() {
    this.dialogRef.close()
    this.router.navigate(["screenlab", "templates", "postermywall", "create"])
  }

  goToHumeCanvaTemplateEditor() {
    this.dialogRef.close()
    this.router.navigate(["screenlab", "templates", "humecanva", "create"])
  }

  chooseInheritingTemplate() {
    this.subscriptions.add(
      this.matDialog.open(InheritableTemplateDialogComponent).afterClosed().pipe(
        filter(templateId => !!templateId),
        switchMap(templateId =>
          this.templateService.getTemplate(templateId).pipe(
            map(template => this.defineRouteParameters(template))
          )
        )
      ).subscribe(route => {
        this.dialogRef.close();
        this.router.navigate(route);
      })
    );
  }

  defineRouteParameters(template: Template): string[] {
    switch (template.type) {
      case TemplateType.HUMECANVA:
        if (this.canCreateFullInherited) {
          return ["screenlab", "templates", "humecanva", template.id as string, "full-instances", "create"]
        }
        return ["screenlab", "templates", "humecanva", template.id as string, "instances", "create"]
      case TemplateType.CLASSIC:
        if (this.canCreateFullInherited) {
          return ["screenlab", "templates", template.id as string, "full-instances", "create"]
        }
        return ["screenlab", "templates", template.id as string, "instances", "create"]
    }
    return []
  }

  chooseLegacyTemplate() {
    this.dialogRef.close()
    this.matDialog.open(LegacyTemplateDialogComponent)
  }
}
