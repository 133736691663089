<form class="cmn-media-update-dialog" id="mediaForm" [formGroup]="mediaForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="cmn-media-update-dialog__content">
    <div *ngIf="userHasLegacyDevice && media.type==MediaType.Dynamic_Template"
      class="cmn-media-update-dialog__content__legacy-device">
      {{ 'DEVICE.WARNING.DEVICE_NOT_SUPPORTED' | translate : {type: 'DEVICE.TYPE.SCR_RASPBERRY_PI' | translate} }}
    </div>

    <button class="cmn-media-update-dialog__content__close-button" mat-button type="button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>

    <div *ngIf="media.externalMediaSource">
      <ng-container [ngSwitch]="media.externalMediaSource">
        <img class="cmn-media-update-dialog__content__external-source-logo"
          *ngSwitchCase="ExternalMediaSource.PosterMyWall"
          [matTooltip]="'MEDIA.EXTERNAL_SOURCE.POSTER_MY_WALL.TOOLTIP' | translate"
          src="/assets/images/badges/badge-postermywall.jpeg">
      </ng-container>
    </div>
    <button [matTooltip]="'MEDIA.UPLOAD.TOOLTIP'| translate" *ngIf="showEditMediaButton$ | async "
      class="cmn-media-update-dialog__content__edit-button" mat-button type="button" (click)="fileDialog.click()">
      <mat-icon>upload_file</mat-icon>
      <input #fileDialog type="file" (change)="onFileChange($event)">
    </button>
    <button [matTooltip]="'MEDIA.ACTION.EDIT_MEDIA.TOOLTIP'| translate" *ngIf="media.type == MediaType.Image"
      class="cmn-media-update-dialog__content__second-button" mat-button type="button" (click)="goToEditPage()">
      <mat-icon>edit</mat-icon>
    </button>
    <button [matTooltip]="'MEDIA.ACTION.FULLSCREEN.TOOLTIP'| translate"
      *ngIf="showFullscreenButton && (showEditMediaButton$ | async )"
      class="cmn-media-update-dialog__content__third-button" mat-button type="button" (click)="viewFullSize(media.url)">
      <mat-icon>fullscreen</mat-icon>
    </button>


    <div class="cmn-media-update-dialog__content__view" [ngSwitch]="media.type">
      <mat-icon *ngSwitchCase="MediaType.Art" inline="true">3d_rotation</mat-icon>
      <audio *ngSwitchCase="MediaType.Audio" controls>
        <source [src]="media.url">
      </audio>
      <img *ngSwitchCase="MediaType.Image" [src]="media.url">
      <img *ngSwitchCase="MediaType.Template" [src]="media.url">
      <mat-icon *ngSwitchCase="MediaType.PDF" inline="true">picture_as_pdf</mat-icon>
      <video *ngSwitchCase="MediaType.Video" controls>
        <source [src]="media.url">
      </video>
      <img *ngSwitchCase="MediaType.Dynamic_Template" [src]="media.thumbnailUrl">
      <mat-icon *ngSwitchDefault inline="true">broken_image</mat-icon>
    </div>

    <mat-tab-group mat-stretch-tabs="false">

      <!-- Parameters -->
      <mat-tab [label]="'MEDIA.DETAILS_DIALOG.TAB.PARAMETERS' | translate">

        <!-- name -->
        <mat-form-field floatLabel="always">
          <mat-label>{{ "MEDIA.FIELDS.NAME.LABEL" | translate }}</mat-label>
          <input matInput formControlName="name" [placeholder]="'MEDIA.FIELDS.NAME.LABEL' | translate" required>
        </mat-form-field>

        <!-- public -->
        <mat-form-field *cmnIfPerm="['scr:media:update:public']">
          <mat-label>{{ "MEDIA.FIELDS.PUBLIC.LABEL" | translate }}</mat-label>
          <mat-select formControlName="public">
            <mat-option [value]="false">{{ "MEDIA.FIELDS.PUBLIC.PRIVATE" | translate }}</mat-option>
            <mat-option [value]="true">{{ "MEDIA.FIELDS.PUBLIC.PUBLIC" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- displayTimeInSec -->
        <mat-form-field floatLabel="always">
          <mat-label>{{ "MEDIA.FIELDS.DISPLAY_TIME_IN_SEC.LABEL" | translate }}</mat-label>
          <input matInput type="number" formControlName="displayTimeInSec"
            [placeholder]="'MEDIA.FIELDS.DISPLAY_TIME_IN_SEC.LABEL' | translate" required>
        </mat-form-field>

        <!-- tags -->
        <span class="cmn-media-update-dialog__content__item" *cmnIfPerm="['scr:media:update:tags']">
          {{ 'TAG.SELECT.LABEL' | translate }}
        </span>
        <div class="cmn-media-update-dialog__content__input">
          <searchable-select panelWidth="auto" formControlName="tags" [showSelectArrow]="true"
            [placeholder]="'TAG.SELECT.PLACEHOLDER' | translate" [multipleSelect]="true" [stickyPlaceholder]="false"
            *cmnIfPerm="['scr:media:update:tags']">
          </searchable-select>
        </div>

        <!-- validityStart / validityEnd -->
        <div>
          <span class="cmn-media-update-dialog__content__item">{{ 'MEDIA.FIELDS.VALIDITY_PERIOD.LABEL' | translate }}</span>
          <app-datetime [dateLabel]="'MEDIA.FIELDS.VALIDITY_PERIOD.START_DATE' | translate" [timeLabel]="'MEDIA.FIELDS.VALIDITY_PERIOD.START_TIME' | translate"
                        [dateMax]="mediaForm.controls['validityEnd'].value"
                        formControlName="validityStart"></app-datetime>
          <app-datetime [dateLabel]="'MEDIA.FIELDS.VALIDITY_PERIOD.END_DATE' | translate" [timeLabel]="'MEDIA.FIELDS.VALIDITY_PERIOD.END_TIME' | translate" [defaultTimeValue]="'23:59'"
                        [dateMin]="mediaForm.controls['validityStart'].value"
                        formControlName="validityEnd"></app-datetime>
          <mat-error *ngIf="!isStartBeforeEnd" class="cmn-media-update-dialog__content__validity-error">
            <span>{{ 'MEDIA.FIELDS.VALIDITY_PERIOD.END-BEFORE-START-ERROR' | translate }}</span>
          </mat-error>
        </div>


        <!-- validity days -->
        <span class="cmn-media-update-dialog__content__item">{{ "MEDIA.FIELDS.VALIDITY_DAYS.LABEL" | translate }}</span>
        <div class="cmn-media-update-dialog__content__input">
          <mat-button-toggle-group [hideMultipleSelectionIndicator]="true" formControlName="selectedDays"
            multiple="true">
            <mat-button-toggle *ngFor="let day of weekDays" [value]="day">{{ moment.weekdaysShort(day) }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <span *ngIf="mediaForm.value.selectedDays.length == 0" class="cmn-media-update-dialog__content__warning">
          {{ "MEDIA.VALIDITY.NO_DAYS" | translate }}
        </span>
      </mat-tab>

      <!-- Informations -->
      <mat-tab [label]="'MEDIA.DETAILS_DIALOG.TAB.INFORMATIONS' | translate">

        <!-- created -->
        <span class="cmn-media-update-dialog__content__item">
          {{ "MEDIA.DETAILS_DIALOG.CREATED" | translate }}
        </span>
        <p>
          <span>{{ media.createdAt | date:'medium' }}</span>
          <span *ngIf="createdBy">{{ "MEDIA.DETAILS_DIALOG.BY" | translate: { name: createdBy } }}</span>
        </p>

        <!-- updated -->
        <span class="cmn-media-update-dialog__content__item">
          {{ "MEDIA.DETAILS_DIALOG.UPDATED" | translate }}
        </span>
        <p>
          <span>{{ media.modifiedAt | date:'medium' }}</span>
          <span *ngIf="modifiedBy">{{ "MEDIA.DETAILS_DIALOG.BY" | translate: { name: modifiedBy } }}</span>
        </p>

        <!-- directory -->
        <span class="cmn-media-update-dialog__content__item">
          {{ "MEDIA.DETAILS_DIALOG.DIRECTORY" | translate }}
        </span>
        <p>
          <span>
            {{ directoryName }}
          </span>
        </p>

        <!-- orientation -->
        @if (media.orientation) {
        <span class="cmn-media-update-dialog__content__item">
          {{ "MEDIA.DETAILS_DIALOG.ORIENTATION" | translate }}
        </span>
        <p>
          <span>{{ "MEDIA.ORIENTATION." + media.orientation | translate }}</span>
          @if (media.width && media.height) {
          <span>({{ media.width }}x{{ media.height }})</span>
          }
        </p>
        }

        <!-- download -->
        <span class="cmn-media-update-dialog__content__item">
          {{ "MEDIA.DETAILS_DIALOG.DOWNLOAD.LABEL" | translate }}
        </span>
        <div class="cmn-media-update-dialog__content__download">
          <div class="cmn-media-update-dialog__content__download__qrcode">
            <qrcode #qrcode [qrdata]="media.url" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
          </div>
          <div class="cmn-media-update-dialog__content__download__buttons">
            <a [href]="media.url" download target="_blank">
              <button mat-button type="button">
                {{ "MEDIA.DETAILS_DIALOG.DOWNLOAD.MEDIA" | translate }}
              </button>
            </a>
            <button mat-button type="button" (click)="downloadQRCode()">
              {{ "MEDIA.DETAILS_DIALOG.DOWNLOAD.QRCODE" | translate }}
            </button>
          </div>
        </div>

      </mat-tab>

      <!-- Admin -->
      <mat-tab [label]="'MEDIA.DETAILS_DIALOG.TAB.ADMIN' | translate"
        *cmnIfPerm="['scr:media:update:domains', 'scr:media:update:app-ids'];op:'OR'">
        <!-- domain -->
        <ng-container *cmnIfPerm="['scr:media:update:domains']">
          <span class="cmn-media-update-dialog__content__item">
            {{ 'DOMAIN.SELECT.LABEL' | translate }}
          </span>
          <div class="cmn-media-update-dialog__content__input">
            <searchable-select panelWidth="auto" formControlName="visibleForDomains" [showSelectArrow]="true"
              [placeholder]="'DOMAIN.SELECT.PLACEHOLDER' | translate" [multipleSelect]="true"
              [stickyPlaceholder]="false">
            </searchable-select>
          </div>
        </ng-container>
        <!-- appIds -->
        <ng-container *cmnIfPerm="['scr:media:update:app-ids']">
          <span class="cmn-media-update-dialog__content__item">
            {{ 'MEDIA.FIELDS.APP_IDS.LABEL' | translate }}
          </span>
          <div class="cmn-media-update-dialog__content__input">
            <searchable-select panelWidth="auto" formControlName="visibleForAppIds" [showSelectArrow]="true"
              [placeholder]="'MEDIA.FIELDS.APP_IDS.PLACEHOLDER' | translate" [multipleSelect]="true"
              [stickyPlaceholder]="false">
            </searchable-select>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions class="cmn-media-update-dialog__actions">
    <button mat-button type="button" class="cmn-media-update-dialog__actions__cancel" (click)="doClose()">
      {{ 'CANCEL' | translate }}
    </button>
    <button mat-button type="button" class="cmn-media-update-dialog__actions__clone" (click)="doDuplicate()">
      {{ 'DUPLICATE' | translate }}
    </button>
    <button *cmnIfPerm="['scr:media:force-encode']" id="forceEncodeButton" mat-button type="button"
      class="cmn-media-update-dialog__actions__force-encode" (click)="doForceEncode()">
      {{ 'MEDIA.ACTION.FORCE_ENCODE' | translate }}
    </button>
    <button *ngIf="showEditTemplateButton$ | async" mat-button type="button"
      class="cmn-media-update-dialog__actions__update-template" (click)="doEditTemplate()"
      [disabled]="!isCustomerScope">
      {{ 'MEDIA.ACTION.UPDATE_TEMPLATE' | translate }}
    </button>
    <span class="fill-remaining-space"></span>
    <button *cmnIfPerm="['scr:media:delete'];op:'OR'" class="cmn-media-update-dialog__actions__delete" mat-button
      type="button" id="deleteMediaButton" (click)="doDelete()" [disabled]="!isCustomerScope">
      {{ 'DELETE' | translate }}
    </button>
    <button *cmnIfPerm="['scr:media:update:unrestricted','scr:media:update:tags'];op:'OR'"
      class="cmn-media-update-dialog__actions__save" mat-button type="submit"
      [disabled]="!isCustomerScope || !mediaForm.valid">
      {{ 'SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</form>
