import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { interval, of, Subscription, zip } from 'rxjs';
import { filter, mergeMap, takeWhile } from 'rxjs/operators';
import { Media, MediaStatus, MediaType, ExternalMediaSource } from '../../../../interfaces/media';
import { MediaService } from '../../../../services/media/media.service';
import { QueryScope, TagService, TargetType, SnackBarService } from "hcl-lib"
import * as moment from 'moment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DirectoryService } from '../../../../services/directory/directory.service';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-media-tile',
  templateUrl: './media-tile.component.html',
  styleUrls: ['./media-tile.component.scss'],
  standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase, MatTooltipModule, MatProgressSpinnerModule, TranslateModule]
})
export class MediaTileComponent implements OnInit, OnDestroy, OnChanges {

  @Input() media!: Media
  @Input() mediaId!: string
  @Input() showIncludedInScenarioIcon: boolean = false
  @Input() showName: boolean = true
  @Input() showTooltip: boolean = true
  @Input() showBadges: boolean = true
  @Input() showBorder: boolean = true

  subscriptions: Subscription = new Subscription()

  thumbnailUrl!: String

  showValidityBadge: Boolean = false
  validityBadgeTooltip!: string
  validityBadgeSrc!: string

  mediaTagNames: any
  mediaDirectoryName?: string | null = null
  mediaInfos!: string
  mediaFailedTooltip!: string

  ExternalMediaSource: typeof ExternalMediaSource = ExternalMediaSource

  constructor(
    private mediaService: MediaService,
    private translateService: TranslateService,
    private tagService: TagService,
    private directoryService: DirectoryService,
    private snackBarService: SnackBarService,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    if (this.media) {
      this.retrieveMediaInfos()
      this.init()
    } else {
      this.retrieveMedia(this.mediaId)
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['mediaId']) {
      this.retrieveMedia(this.mediaId)
    }
  }

  retrieveMedia(mediaId: string): void {
    this.subscriptions.add(
      this.mediaService.getMedia(mediaId, undefined, QueryScope.CUSTOMER_AND_ANCESTORS).subscribe(media => {
        this.media = media
        this.init()
      })
    )
  }

  init(): void {
    if (this.media.type === MediaType.Audio) {
      this.thumbnailUrl = "/assets/images/screenlab/audio-media.svg"
    } else {
      this.thumbnailUrl = this.media.thumbnailUrl
    }
    this.checkValidityDates()
    this.checkValidityDays()
    this.pollEncoding()
  }

  checkValidityDays(): void {
    let size = 0
    this.media.validityDays?.forEach((value, key) => {
      if (value) {
        size++
      }
    })

    if (size === 0) {
      this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.NO_DAYS')
      this.validityBadgeSrc = '/assets/images/badges/badge-validity-expired.svg'
      this.showValidityBadge = true
    }
  }

  checkValidityDates(): void {
    const now = new Date()
    if (this.media.validityStart && this.media.validityEnd) {
      const validityStart = new Date(this.media.validityStart)
      const validityEnd = new Date(this.media.validityEnd)
      if (now < validityStart) {
        this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.VALID_AT', { date: this.formatTooltipDate(validityStart) })
        this.validityBadgeSrc = '/assets/images/badges/badge-validity-at.svg'
        this.showValidityBadge = true
      } else if (now > validityEnd) {
        this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.EXPIRED')
        this.validityBadgeSrc = '/assets/images/badges/badge-validity-expired.svg'
        this.showValidityBadge = true
      } else {
        this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.VALID_UNTIL', { date: this.formatTooltipDate(validityEnd) })
        this.validityBadgeSrc = '/assets/images/badges/badge-validity-valid.svg'
        this.showValidityBadge = true
      }
    } else if (this.media.validityStart) {
      const validityStart = new Date(this.media.validityStart)
      if (now < validityStart) {
        this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.VALID_AT', { date: this.formatTooltipDate(validityStart) })
        this.validityBadgeSrc = '/assets/images/badges/badge-validity-at.svg'
        this.showValidityBadge = true
      }
    } else if (this.media.validityEnd) {
      const validityEnd = new Date(this.media.validityEnd)
      if (now < validityEnd) {
        this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.VALID_UNTIL', { date: this.formatTooltipDate(validityEnd) })
        this.validityBadgeSrc = '/assets/images/badges/badge-validity-valid.svg'
        this.showValidityBadge = true
      } else {
        this.validityBadgeTooltip = this.translateService.instant('MEDIA.VALIDITY.EXPIRED')
        this.validityBadgeSrc = '/assets/images/badges/badge-validity-expired.svg'
        this.showValidityBadge = true
      }
    }
  }

  formatTooltipDate(date: Date): string {
    return date.toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }

  pollEncoding(): void {
    if (this.media.status === MediaStatus.Processing) {
      this.subscriptions.add(
        interval(10000).pipe(
          mergeMap(_ => this.mediaService.getMedia(this.media.id)),
          takeWhile(media => media.status === MediaStatus.Processing, true),
          filter(media => media.status !== MediaStatus.Processing)
        ).subscribe(media => {
          this.media = media
          if (media.status == MediaStatus.Success) {
            this.snackBarService.snackBarSuccess('MEDIA.IMPORT.SUCCESS')
          } else if (media.status == MediaStatus.Failed) {
            this.snackBarService.snackBarError('MEDIA.IMPORT.ERROR.' + (media.errorKey != undefined ? media.errorKey : 'GENERIC'))
          }
        })
      )
    }
  }

  retrieveMediaInfos() {
    this.subscriptions.add(
      this.mediaService.getMedia(this.media.id, undefined, QueryScope.CUSTOMER_AND_ANCESTORS).pipe(
        mergeMap(media => {
          this.mediaFailedTooltip = (media.errorKey == undefined || media.errorKey == null) ? 'GENERIC' : media.errorKey
          return zip(
            media.tagIds?.length ? this.tagService.getTagsByIds(media.tagIds) : of([]),
            media.directoryId ? this.directoryService.getDirectories() : of([])
          )
        })
      ).subscribe(([tags, directories]) => {
        this.mediaTagNames = tags.map(tag => tag.name)
        const directoryFound = directories.find(directory => directory.id == this.media.directoryId)
        this.mediaDirectoryName = directoryFound ? directoryFound.name : null
        this.updateTooltip(this.media.displayTimeInSec)
      })
    )
  }

  updateTooltip(mediaDuration: number) {
    const duration = moment.duration(mediaDuration, 'seconds');
    let tooltipStr = this.translateService.instant("SLIDE.DURATION") + ": "
      + duration.minutes() + " " + this.translateService.instant("DURATION.MINUTES") + " " + duration.seconds()
      + " " + this.translateService.instant("DURATION.SECONDS")

    if (this.mediaTagNames.length > 0) {
      const tagsStr = this.translateService.instant("SLIDE.TAGS") + ": " + this.mediaTagNames.join(", ")
      tooltipStr = tooltipStr + "\n" + tagsStr
    }

    if (this.mediaDirectoryName) {
      const directoryStr = this.translateService.instant("SLIDE.DIRECTORY") + ": " + this.mediaDirectoryName
      tooltipStr = tooltipStr + "\n" + directoryStr
    }

    this.mediaInfos = tooltipStr
  }
}
