<h1 mat-dialog-title class="directory-dialog__title" i18n>{{ title }}</h1>

<form [formGroup]="directoryForm" (ngSubmit)="doSubmit()">
  <mat-dialog-content class="directory-dialog__content">
    <mat-form-field>
      <input matInput placeholder="{{'CUSTOMER.NAME' | translate}}" formControlName="name" required>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="directory-dialog__actions">
    <button mat-button mat-dialog-close type="button">{{'CANCEL' | translate}}</button>
    <button
      mat-button
      type="button"
      *ngIf="directory.id"
      [disabled]="directoryMedias.length > 0 || directory.children?.length! > 0"
      [matTooltip]="'DIRECTORY.CANNOT_DELETE_TOOLTIP' | translate"
      [matTooltipDisabled]="directoryMedias.length == 0 && directory.children?.length == 0"
      (click)="doDelete()">
      {{'DELETE' | translate}}
    </button>
    <button mat-button type="submit" [disabled]="!directoryForm.valid">{{'SAVE' | translate}}</button>
  </mat-dialog-actions>
</form>
