import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AddSpaceBeforeColonPipe, KeycloakService, ScopeService, SnackBarService } from 'hcl-lib';
import { DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { environment } from 'projects/hcl-portal/src/environments/environment';
import { interval, Subscription, of, zip } from 'rxjs';
import { filter, mergeMap, takeWhile } from "rxjs/operators"
import { MediaType, Media, MediaStatus } from "../../../interfaces/media";
import { DeviceService } from "projects/hcl-portal/src/app/common/services/device/device.service";
import { Device, DeviceType } from "projects/hcl-portal/src/app/common/interfaces/device";
import { MediaService } from 'projects/hcl-portal/src/app/common/services/media/media.service';
import { NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: 'app-media-create-dialog',
    templateUrl: './media-create-dialog.component.html',
    styleUrls: ['./media-create-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogModule, MatButtonModule, TranslateModule, DropzoneModule, AddSpaceBeforeColonPipe]
})
export class MediaCreateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  token?: string

  maxFileSizeInMo = 250
  acceptedFilesStr = ''
  acceptedFiles: string[] = []
  acceptedFileExtensionsStr = ""
  acceptedFileExtensions: string[] = []
  uploadError = false
  uploadFinished = false
  errorTranslationKey = ""
  media: Media | null = null

  userHasLegacyDevice: boolean = false
  uploadingDynamicTemplate: boolean = false

  dropzoneConfig!: DropzoneConfigInterface

  constructor(
    public dialogRef: MatDialogRef<MediaCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private keycloakService: KeycloakService,
    private scopeService: ScopeService,
    private deviceService: DeviceService,
    private snackBarService: SnackBarService,
    private mediaService: MediaService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.configureDropzone()
    this.loadKeycloakToken()
    this.hasLegacyDevice()
  }

  configureDropzone(): void {
    this.subscriptions.add(
      zip(
        this.scopeService.hasPermission("scr:media:create:audio"),
        this.scopeService.hasPermission("scr:media:create:image"),
        this.scopeService.hasPermission("scr:media:create:pdf"),
        this.scopeService.hasPermission("scr:media:create:video"),
        this.scopeService.hasPermission("scr:media:create:dynamic-template"),
        this.scopeService.getScopeObservable()
      ).subscribe(([allowAudio, allowImg, allowPdf, allowVideo, allowDynamicTemplate, scope]) => {
        let managedMediaTypes: MediaType[] = this.data.managedMediaTypes

        if (allowAudio && managedMediaTypes.includes(MediaType.Audio)) {
          this.acceptedFileExtensions.push("mp3")
          this.acceptedFiles.push("audio/mpeg")
        }
        if (allowImg && managedMediaTypes.includes(MediaType.Image)) {
          this.acceptedFileExtensions.push("jpg", "jpeg", "png", "heic", "heif")
          this.acceptedFiles.push('image/jpeg', 'image/png', 'image/heic', 'image/heif')
        }
        if (allowPdf && managedMediaTypes.includes(MediaType.PDF)) {
          this.acceptedFileExtensions.push("pdf")
          this.acceptedFiles.push('application/pdf')
        }
        if (allowVideo && managedMediaTypes.includes(MediaType.Video)) {
          this.acceptedFileExtensions.push("mp4", "avi", "mov")
          this.acceptedFiles.push('video/mp4', "video/avi", "video/quicktime", "video/vnd.avi", "video/msvideo", "video/x-msvideo")
        }
        if (allowDynamicTemplate && managedMediaTypes.includes(MediaType.Dynamic_Template)) {
          this.acceptedFiles.push('application/zip')
          this.acceptedFileExtensions.push('zip')
        }

        this.acceptedFileExtensionsStr = this.acceptedFileExtensions.join(", ")
        this.acceptedFilesStr = this.acceptedFiles.join(",")
        this.dropzoneConfig = {
          url: this.buildUrl(scope as string),
          maxFilesize: this.maxFileSizeInMo,
          timeout: 180000,
          acceptedFiles: this.acceptedFilesStr
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  loadKeycloakToken(): void {
    this.subscriptions.add(
      this.keycloakService.getAutoRefreshToken().subscribe(token => {
        this.token = token
      })
    )
  }

  pollEncoding(): void {
    if (this.media !== null && this.media.status) {
      if (this.media.status === MediaStatus.Processing) {
        this.subscriptions.add(
          interval(10000).pipe(
            mergeMap(_ => this.mediaService.getMedia(this.media?.id as string)),
            takeWhile(media => media.status === MediaStatus.Processing, true),
            filter(media => media.status !== MediaStatus.Processing)
          ).subscribe(media => {
            this.media = media
            if (media.status == MediaStatus.Success) {
              this.snackBarService.snackBarSuccess('MEDIA.IMPORT.SUCCESS')
            } else if (media.status == MediaStatus.Failed) {
              this.snackBarService.snackBarError('MEDIA.IMPORT.ERROR.' + (media.errorKey != undefined ? media.errorKey : 'GENERIC'))
            }
          })
        )
      }
    }
  }

  // data [ File , xhr, formData ]
  onSending(data: any) {
    let xhr = data[1]
    this.uploadingDynamicTemplate = data[0].type == 'application/zip'
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.token)
  }

  onUploadSuccess(data: any) {
    this.media = data[1].data
    this.pollEncoding()
  }

  onUploadError(data: any) {
    if (typeof data[1] == "string") {
      this.errorTranslationKey = 'MEDIA.IMPORT.ERROR.UPLOAD_CANCELLED' // This is a fringe case, data comes out as a string saying "Upload Cancelled!"
    } else if (data[1].failure) {
      this.errorTranslationKey = data[1].failure.translationKey == null ? 'MEDIA.IMPORT.ERROR.GENERIC' : data[1].failure.translationKey
    } else {
      this.errorTranslationKey = 'MEDIA.IMPORT.ERROR.GENERIC'
    }
    this.media = data[1].data
    this.snackBarService.snackBarError(this.errorTranslationKey)
    this.uploadFinished = true
    this.uploadError = true
  }

  hasLegacyDevice(): void {
    this.subscriptions.add(
      this.deviceService.getDevicesWithPaging(0, 1000).pipe(
        mergeMap(devices => of(devices.data.some((device: Device) => device.type == DeviceType.SCR_RASPBERRY_PI)))
      ).subscribe((hasLegacyDevice) => {
        this.userHasLegacyDevice = hasLegacyDevice
      })
    )
  }

  buildUrl(scope: string): string {
    let directoryId = this.data.directoryId
    let url = `${environment.mediaApiBaseUrl}/medias?cid=${scope}`
    if (directoryId) {
      url += `&directoryId=${directoryId}`
    }
    return url
  }

  doClose(): void {
    this.dialogRef.close(this.media)
  }
}
