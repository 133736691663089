<div class="inheritable-template-dialog__title">
  <span>{{'TEMPLATE.CREATE_VISUAL' | translate}}</span>
</div>

<mat-dialog-content>
  <mat-paginator [pageSize]="10" [hidePageSize]="true" (page)="handlePage()"></mat-paginator>
  <div class="inheritable-template-dialog__content">
    <div *ngFor="let mediaTemplate of templateDataSource.data" class="inheritable-template-dialog__content__template"
      [class.selected]="mediaTemplate === selectedTemplate" (click)="onSelect(mediaTemplate)">
      <div class="inheritable-template-dialog__content__template__preview">
        <img [src]="mediaTemplate.thumbnailUrl">
      </div>
      <div class="inheritable-template-dialog__content__template__desc">
        {{ mediaTemplate.mediaName }}
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="inheritable-template-dialog__actions">
  <button mat-button mat-dialog-close>
    {{'CANCEL' | translate}}
  </button>
  <button mat-button class="inheritable-template-dialog__actions__update" [disabled]="selectedTemplate == null" (click)="doSelect()">
    {{'CREATE' | translate}}
  </button>
</mat-dialog-actions>
