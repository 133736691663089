import { AsyncPipe, DatePipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, WeekDay } from "@angular/common"
import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import {
  CmnIfPermDirective,
  DayOfWeek,
  DeleteDialogComponent,
  Domain,
  HumecloudRoles,
  QueryScope,
  ScopeService,
  TagService,
  TargetType
} from "hcl-lib"
import { ExternalMediaSource, Media, MediaType } from "projects/hcl-portal/src/app/common/interfaces/media"
import { MediaService } from "projects/hcl-portal/src/app/common/services/media/media.service"
import { merge, Observable, of, Subscription, zip} from "rxjs"
import { filter, map, mergeMap } from "rxjs/operators"
import { Template, TemplateType } from "../../../interfaces/template"
import { AppService } from "../../../services/app/app.service"
import { TemplateService } from "../../../services/template/template.service"
import { TimeSlotUtil } from "../../../util/timeslot.util"
import { MediaDuplicateDialogComponent } from "../media-duplicate-dialog/media-duplicate-dialog.component"
import { QRCodeComponent, QRCodeModule } from "angularx-qrcode"
import { DeviceService } from "projects/hcl-portal/src/app/common/services/device/device.service"
import { Device, DeviceType } from "projects/hcl-portal/src/app/common/interfaces/device"
import { MediaReencodeDialogComponent } from "../media-reencode-dialog/media-reencode-dialog.component";
import { MediaFullsizeDialogComponent } from "../media-fullsize-dialog/media-fullsize-dialog/media-fullsize-dialog.component"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { MatTabsModule } from "@angular/material/tabs"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { SearchableSelectComponent } from "../../../Layout/Components/widget/searchable-select/searchable-select.component"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatDialogModule } from "@angular/material/dialog"
import { MatTooltipModule } from "@angular/material/tooltip"
import {DirectoryService} from "../../../services/directory/directory.service";
import {DateTimeFormComponent} from "../../../Layout/Components/widget/date-time-form/date-time-form.component";
import moment from "moment";

@Component({
  selector: "app-media-update-dialog",
  templateUrl: "./media-update-dialog.component.html",
  styleUrls: ["./media-update-dialog.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe, DatePipe, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatDatepickerModule, MatTabsModule, MatButtonModule, MatButtonToggleModule, MatIconModule, MatDialogModule, MatTooltipModule, TranslateModule, CmnIfPermDirective, QRCodeModule, SearchableSelectComponent, DateTimeFormComponent]
})
export class MediaUpdateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  createdBy?: string
  modifiedBy?: string

  MediaType = MediaType
  moment = moment
  weekDays: WeekDay[] = [
    WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday, WeekDay.Saturday, WeekDay.Sunday
  ]

  media!: Media
  mediaForm!: UntypedFormGroup
  showEditMediaButton$!: Observable<boolean>
  showEditTemplateButton$!: Observable<boolean>
  showFullscreenButton!: boolean
  canEditFullInherited = false
  template!: Template

  userHasLegacyDevice: boolean = false

  isCustomerScope = false

  ExternalMediaSource: typeof ExternalMediaSource = ExternalMediaSource

  @ViewChild("fileDialog") fileDialog!: ElementRef
  @ViewChild("qrcode", { static: true }) qrcode!: QRCodeComponent

  ADMIN_ROLE = HumecloudRoles.ADMIN
  SUPERADMIN_ROLE = HumecloudRoles.SUPERADMIN
  isStartBeforeEnd: boolean = true;
  directoryName: string | null = "" ;

  constructor(
    public dialogRef: MatDialogRef<MediaUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private appService: AppService,
    private mediaService: MediaService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private tagService: TagService,
    private router: Router,
    private scopeService: ScopeService,
    private templateService: TemplateService,
    private deviceService: DeviceService,
    private directoryService: DirectoryService,
    @Inject(LOCALE_ID) public locale: string,
    public matDialog: MatDialog
  ) {
  }

  goToEditPage(): void {
    this.router.navigate(['/screenlab/editMedia/' + this.media.id]);
    this.dialogRef.close();
  }

  ngOnInit(): void {
    moment.locale(this.locale)
    this.media = this.data.media
    this.hasLegacyDevice()

    this.subscriptions.add(this.mediaService.getMedia(
      this.media.id,
      undefined,
      undefined,
      true
    ).subscribe(it => {
      this.createdBy = it.createdByUserName
      this.modifiedBy = it.modifiedByUserName
    }))

    this.retrieveDirectoryName()
    this.mediaForm = this.formBuilder.group({
      name: [this.media.name, Validators.required],
      visibleForDomains: [Object.keys(Domain).map(domain => ({
        id: Domain[domain as keyof typeof Domain],
        name: this.translateService.instant("DOMAIN." + domain),
        selected: this.media.visibleForDomains?.includes(domain)
      }))],
      visibleForAppIds: [[]],
      tags: [[], Validators.nullValidator],
      public: [{ value: this.media.public, disabled: this.media.public }, Validators.required],
      displayTimeInSec: [{
        value: this.media.displayTimeInSec,
        disabled: (this.media.type === MediaType.Audio || this.media.type === MediaType.Video)
      }, Validators.min(0)],
      validityStart: [this.media.validityStart ? new Date(this.media.validityStart) : undefined],
      validityEnd: [this.media.validityEnd ? new Date(this.media.validityEnd) : undefined],
      selectedDays: [[...(this.media.validityDays as Set<DayOfWeek>)].map(day => TimeSlotUtil.getDayOfWeekMoment(day))],
    })

    const validityStartField$ = this.mediaForm.get('validityStart')?.valueChanges ?? of(null);
    const validityEndField$ = this.mediaForm.get('validityEnd')?.valueChanges ?? of(null);

    this.subscriptions.add(
      merge(
        validityStartField$.pipe(map(value => ({ field: 'validityStart', value }))),
        validityEndField$.pipe(map(value => ({ field: 'validityEnd', value })))
      ).subscribe((change) => {
        const validityStartValue = this.mediaForm.get('validityStart')?.value
        const validityEndValue = this.mediaForm.get('validityEnd')?.value
        if (validityStartValue && validityEndValue) {
          if (new Date(validityStartValue).getTime() > new Date(validityEndValue).getTime()) {
            this.isStartBeforeEnd = false;
            this.mediaForm.get('validityEnd')?.setErrors({ 'validityEndError': true });
          } else if (new Date(validityStartValue).getTime() === new Date(validityEndValue).getTime()) {
            this.isStartBeforeEnd = false;
            this.mediaForm.get('validityEnd')?.setErrors({ 'validityEndError': true });
          } else {
            this.isStartBeforeEnd = true;
            this.mediaForm.get('validityEnd')?.setErrors(null);
          }
        } else if (validityStartValue || validityEndValue) {
          this.isStartBeforeEnd = true;
          this.mediaForm.get('validityEnd')?.setErrors(null);
        } else {
          // do nothing
        }
      })
    );

    this.isCustomerScope = this.media.customerId === this.scopeService.getScope()
    this.showFullscreenButton = (this.media.type === MediaType.Image || this.media.type === MediaType.Template)
    this.showEditMediaButton$ =
      zip(
        this.scopeService.hasPermission("scr:media:update:audio"),
        this.scopeService.hasPermission("scr:media:update:image"),
        this.scopeService.hasPermission("scr:media:update:video"),
        this.scopeService.hasPermission("scr:media:update:pdf")
      ).pipe(
        map(([allowAudio, allowImage, allowVideo, allowPdf]) => {
          return this.isCustomerScope && this.media.externalMediaSource == null
            && ((this.media.type === MediaType.Audio && allowAudio)
              || (this.media.type === MediaType.Image && allowImage)
              || (this.media.type === MediaType.Video && allowVideo)
              || (this.media.type === MediaType.PDF && allowPdf))
        })
      )
    if (this.media.type === MediaType.Template) {
      this.showEditTemplateButton$ =
        zip(
          this.templateService.getTemplate(this.media.templateId as string),
          this.scopeService.hasPermission("scr:media:update:inherited-template"),
          this.scopeService.hasPermission("scr:media:update:template")
        ).pipe(
          map(([template, editInheritedTemplate, editTemplate]) => {
            this.template = template
            return (template.templateId && editInheritedTemplate) || (!template.templateId && editTemplate)
          })
        )
      this.subscriptions.add(
        this.scopeService.hasPermission("scr:template:update:full-inherited")
          .subscribe(canEditFullInherited => this.canEditFullInherited = canEditFullInherited)
      )
    } else if (this.media.externalMediaSource != null) {
      if (this.media.externalMediaSource == ExternalMediaSource.PosterMyWall) {
        this.showEditTemplateButton$ = this.scopeService.hasPermission("scr:media:update:postermywall");
      }
    } else {
      this.showEditTemplateButton$ = of(false)
    }
    if (!this.isCustomerScope) {
      this.mediaForm.disable()
    }
    this.subscriptions.add(
      this.tagService.getAvailableTags([TargetType.MEDIA]).subscribe(tags => {
        this.mediaForm.controls['tags'].setValue(tags.map(tag => {
          return { id: tag.id, name: tag.name, selected: this.media.tagIds?.includes(tag.id as string) }
        }))
      })
    )
    this.initVisibleForAppIds()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  hasLegacyDevice(): void {
    this.subscriptions.add(
      this.deviceService.getDevicesWithPaging(0, 1000).pipe(
        mergeMap(devices => of(devices.data.some((device: Device) => device.type == DeviceType.SCR_RASPBERRY_PI)))
      ).subscribe((hasLegacyDevice) => {
        this.userHasLegacyDevice = hasLegacyDevice
      })
    )
  }

  retrieveDirectoryName() {
    this.subscriptions.add(
      this.mediaService.getMedia(this.media.id, undefined, QueryScope.CUSTOMER_AND_ANCESTORS).pipe(
        mergeMap( media => {
          return media.directoryId ?  this.directoryService.getDirectories() :  of([])
        })).subscribe((directories) => {
        const directoryFound = directories.find(directory => directory.id == this.media.directoryId)
        this.directoryName = directoryFound ? directoryFound.name : this.translateService.instant('MEDIA.DETAILS_DIALOG.NO_DIRECTORY')
      })
    )
  }

  onSubmit(): void {
    if (this.mediaForm.valid) {
      const rawValue = this.mediaForm.getRawValue()
      this.media.name = rawValue.name
      this.media.visibleForDomains = this.formatSearchableData(rawValue.visibleForDomains)
      this.media.visibleForAppIds = this.formatSearchableData(rawValue.visibleForAppIds)
      this.media.tagIds = this.formatSearchableData(rawValue.tags)
      this.media.public = rawValue.public
      this.media.displayTimeInSec = rawValue.displayTimeInSec
      this.media.validityStart = rawValue.validityStart
      this.media.validityEnd = rawValue.validityEnd
      this.media.validityDays = rawValue.selectedDays.map((day: number) => TimeSlotUtil.getDayOfWeek(day))
      this.subscriptions.add(
        this.mediaService.updateMedia(this.media).subscribe(_ => {
          this.dialogRef.close()
        })
      )
    }
  }

  formatSearchableData(values: any[]): string[] {
    return values.map(value => {
      if (typeof value === "string" || value instanceof String) {
        return value
      } else if (value.selected) {
        return value.id
      }
    }).filter(value => value !== undefined)
  }

  doDelete(): void {
    if (this.media.type === MediaType.Image) {
      this.subscriptions.add(
        this.templateService.getTemplatesWithPaging(
          0, 3, undefined, undefined, undefined, this.media.id
        ).subscribe(invocationResult => {
          var templateNames = ""
          if (invocationResult.data.length > 0) {
            for (let template of invocationResult.data) {
              if (template.name) {
                templateNames += `<li> ${template.name} </li>`
              } else {
                templateNames += `<li> Template </li>`
              }
            }
            var warningMessage = this.translateService.instant("MEDIA.DELETE.IN_TEMPLATES", { numberOfTemplates: invocationResult.pagination?.count });
            this.openDeleteDialog(
              this.translateService.instant("MEDIA.DELETE.TITLE"),
              `<p>${warningMessage}</p><ul>${templateNames}</ul>`
            )
          } else {
            this.openDeleteDialog(
              this.translateService.instant("MEDIA.DELETE.TITLE"),
              this.translateService.instant("MEDIA.DELETE.CONTENT")
            )
          }
        }))
    } else {
      this.openDeleteDialog(
        this.translateService.instant("MEDIA.DELETE.TITLE"),
        this.translateService.instant("MEDIA.DELETE.CONTENT")
      )
    }
  }

  openDeleteDialog(title: string, content: string): void {
    const config = new MatDialogConfig()
    config.panelClass = 'humecloud-dialog-container'
    config.data = { title: title, content: content }
    const deleteDialogRef = this.matDialog.open(DeleteDialogComponent, config)
    this.subscriptions.add(
      deleteDialogRef.afterClosed().pipe(
        mergeMap(value => {
          if (value) {
            return this.mediaService.deleteMedia(this.media.id)
          }
          return of(false)
        })
      ).subscribe((deleted: any) => {
        if (deleted) {
          this.doClose()
        }
      })
    )
  }

  doClose(): void {
    this.dialogRef.close()
  }

  doDuplicate(): void {
    const config = new MatDialogConfig()
    config.data = {
      media: this.media
    }
    const mediaDuplicateDialogRef = this.matDialog.open(MediaDuplicateDialogComponent, config)
    this.subscriptions.add(
      mediaDuplicateDialogRef.afterClosed().subscribe(cancelled => {
        if (!cancelled) {
          this.dialogRef.close()
        }
      })
    )
  }

  doForceEncode(): void {
    const config = new MatDialogConfig()
    config.data = {
      media: this.media
    }
    const mediaReencodeDialogRef = this.matDialog.open(MediaReencodeDialogComponent, config)
    this.subscriptions.add(
      (mediaReencodeDialogRef as MatDialogRef<MediaReencodeDialogComponent, any>).afterClosed().subscribe(cancelled => {
        if (!cancelled) {
          this.dialogRef.close()
        }
      })
    )
  }

  defineRouteParameters(template: Template): string[] {
    let route: string[] = []
    switch (template.type) {
      case TemplateType.CLASSIC:
        if (template.templateId) {
          if (this.canEditFullInherited) {
            route = ["screenlab", "templates", template.templateId, "full-instances", template.id as string]
          } else {
            route = ["screenlab", "templates", template.templateId, "instances", template.id as string]
          }
        }
        break
      case TemplateType.LEGACY:
        if (template.legacyFormat) {
          route = ["screenlab", "templates", template.legacyFormat, "legacy", template.id as string]
        }
        break
      case TemplateType.HUMECANVA:
        if (template.templateId) {
          if (this.canEditFullInherited) {
            route = ["screenlab", "templates", "humecanva", template.templateId, "full-instances", template.id as string]
          } else {
            route = ["screenlab", "templates", "humecanva", template.templateId, "instances", template.id as string]
          }
        } else {
          route = ["screenlab", "templates", "humecanva",  template.id as string]
        }
        break
    }

    if (route.length == 0) {
      return ["screenlab", "templates", this.media.templateId as string]
    } else {
      return route
    }
  }

  doEditTemplate() {
    this.dialogRef.close();

    if (this.template) {
      this.router.navigate(this.defineRouteParameters(this.template))
      return;
    }

    if (this.media?.externalMediaSource == ExternalMediaSource.PosterMyWall) {
      this.router.navigate(["screenlab", "templates", "postermywall", "edit", this.media.externalMediaId])
    }
  }

  onFileChange(event: Event): void {
    const files: FileList | null = (event.target as HTMLInputElement).files
    if (files !== null) {
      this.mediaService.updateMediaFile(this.media.id, files[0]).subscribe(_ => {
        this.dialogRef.close()
      })
    }
  }

  clearValidityDates() {
    this.mediaForm.patchValue({
      validityStart: undefined,
      validityEnd: undefined,
    })
  }

  initVisibleForAppIds(): void {
    this.subscriptions.add(
      this.scopeService.hasPermission("scr:media:update:app-ids").pipe(
        filter(canUpdateAppIds => canUpdateAppIds),
        mergeMap(_ => this.appService.getApps())
      ).subscribe(apps => {
        this.mediaForm.controls['visibleForAppIds'].setValue(
          apps.map(app => {
            return {
              id: app.id,
              name: this.translateService.instant("APPS." + app.name?.toUpperCase() + ".NAME"),
              selected: this.media.visibleForAppIds?.includes(app.id)
            }
          })
        )
      })
    )
  }

  downloadQRCode(): void {
    const canvas = this.qrcode.qrcElement.nativeElement.firstChild
    const dataUrl = canvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.href = dataUrl
    link.download = 'qrcode.png'
    link.click()
  }

  viewFullSize(url: string): void {
    const config = new MatDialogConfig()
    config.height = '95%'
    config.width = '150%'
    config.data = {
      mediaUrl: url
    }
    const mediaFullSizeDialogRef = this.matDialog.open(MediaFullsizeDialogComponent, config)
    this.subscriptions.add(
      mediaFullSizeDialogRef.afterClosed().subscribe(cancelled => {
        if (!cancelled) {
          this.dialogRef.close()
        }
      })
    )
  }
}
