<div class="directory">
  <mat-radio-group [(ngModel)]="directoryMode">
    <mat-radio-button *ngFor="let mode of directoryModeKeys" [value]="mode" (change)="onRadioChange($event)">
      {{ ("DIRECTORY_MODE." + mode) | translate }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-tree *ngIf="directoryMode == DirectoryMode.TREE" [dataSource]="directoryDataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node class="node-draw-container" *matTreeNodeDef="let node" matTreeNodeToggle>
      <div class="node-draw-left"></div>
      <li>
        <div class="tree-node">
          <div class="node-draw-right"></div>
          <div class="node-content" (click)="onNodeClick(node)" [class.selected-directory]="selectedDirectory == node"
            cdkDropList (cdkDropListDropped)="onDrop($event, node)">
            <mat-icon *ngIf="!node.name">home</mat-icon>
            <span class="node-content-name">{{ node.name }}</span>
            <div class="node-content-actions">
              <div *cmnIfPerm="['scr:directory:update'];op:'OR'">
                <button mat-icon-button *ngIf="node.customerId == scopeCid" (click)="editDirectory(node)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
              <button *cmnIfPerm="['scr:directory:create'];op:'OR'" mat-icon-button (click)="createSubdirectory(node)">
                <mat-icon>add_box</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node class="node-draw-container" *matTreeNodeDef="let node; when: hasChild">
      <ng-container *ngIf="node.name != '..'">
        <div class="node-draw-left"></div>
        <li>
          <div class="nested-tree-node">
            <button class="nested-tree-button" mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon>
                {{ treeControl.isExpanded(node) ? 'remove_circle_outline' : 'add_circle_outline' }}
              </mat-icon>
            </button>
            <div class="node-draw-right"></div>
            <div class="node-content" (click)="onNodeClick(node)" [class.selected-directory]="selectedDirectory == node"
              cdkDropList (cdkDropListDropped)="onDrop($event, node)">
              <mat-icon *ngIf="!node.name">home</mat-icon>
              <span class="node-content-name">{{ node.name }}</span>
              <div class="node-content-actions">
                <div *cmnIfPerm="['scr:directory:update'];op:'OR'">
                  <button mat-icon-button *ngIf="node.customerId == scopeCid" (click)="editDirectory(node)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
                <button *cmnIfPerm="['scr:directory:create'];op:'OR'" mat-icon-button (click)="createSubdirectory(node)">
                  <mat-icon>add_box</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <ul>
            <div *ngIf="treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </ul>
        </li>
      </ng-container>
    </mat-nested-tree-node>
  </mat-tree>
</div>
