import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table"
import { QueryScope } from "hcl-lib"
import { from, Observable, of, Subscription, zip } from "rxjs"
import { catchError, mergeMap, tap, toArray } from "rxjs/operators"
import { Media } from "../../../../../interfaces/media"
import { Template } from "../../../../../interfaces/template"
import { MediaService } from "../../../../../services/media/media.service"
import { TemplateService } from "../../../../../services/template/template.service"
import { NgFor } from "@angular/common"
import { MatDialogModule } from "@angular/material/dialog"
import { MatPaginatorModule } from "@angular/material/paginator"
import { TranslateModule } from "@ngx-translate/core"
import { MatButtonModule } from "@angular/material/button"

interface MediaTemplate extends Template {
  mediaName?: string,
  thumbnailUrl?: string
}

@Component({
    selector: 'app-inheritable-template-dialog',
    templateUrl: './inheritable-template-dialog.component.html',
    styleUrls: ['./inheritable-template-dialog.component.scss'],
    standalone: true,
    imports: [NgFor, MatDialogModule, MatPaginatorModule, MatButtonModule, TranslateModule]
})
export class InheritableTemplateDialogComponent implements OnDestroy, AfterViewInit {

  subscriptions: Subscription = new Subscription()

  selectedTemplate?: MediaTemplate
  templateDataSource = new MatTableDataSource<MediaTemplate>()

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator

  constructor(
    public dialogRef: MatDialogRef<InheritableTemplateDialogComponent>,
    private mediaService: MediaService,
    private templateService: TemplateService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngAfterViewInit(): void {
    this.retrieveInheritableTemplates()
  }

  handlePage(): void {
    this.retrieveInheritableTemplates()
  }

  retrieveInheritableTemplates(): void {
    this.subscriptions.add(
      this.templateService.getTemplatesWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        undefined,
        true,
        QueryScope.CUSTOMER_AND_ANCESTORS
      ).pipe(
        tap(invocationResult => this.paginator.length = invocationResult.pagination?.count as number),
        mergeMap(invocationResult => from(invocationResult.data) as Observable<Template>),
        mergeMap((template: Template) => {
          return zip(
            of(template),
            this.mediaService.getMedia(
              template.mediaId as string,
              undefined,
              QueryScope.CUSTOMER_AND_ANCESTORS
            ).pipe(
              catchError(_ => of(null))
            )
          )
        }),
        mergeMap(([template, media]) => {
          let mediaTemplate = template as MediaTemplate
          if (media != null) {
            mediaTemplate.mediaName = (media as Media).name
            mediaTemplate.thumbnailUrl = (media as Media).thumbnailUrl
          }
          return of(mediaTemplate)
        }),
        toArray()
      ).subscribe(mediaTemplates => {
        this.templateDataSource.data = mediaTemplates
      })
    )
  }

  onSelect(mediaTemplate: MediaTemplate): void {
    this.selectedTemplate = mediaTemplate
  }

  doSelect(): void {
    this.dialogRef.close(this.selectedTemplate?.id)
  }
}
