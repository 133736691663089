<div class="cmn-media-list">
  <div class="cmn-media-list__body">

    <ng-container *cmnIfPerm="['scr:media:filter:unrestricted']">
      <ng-container *ngIf="!isMobilePhone">
        <div class="cmn-media-list__body__filter-sidebar">
          <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <div class="cmn-media-list__body__content">
      <div class="cmn-media-list__body__content__toolbar">

        <div class="cmn-media-list__body__content__toolbar__title">
          <span>{{ title }}</span>
          <span class="cmn-media-list__body__content__toolbar__title__sub">{{ subtitle }}</span>
        </div>

        <div class="cmn-media-list__body__content__toolbar__dropdown-section">
          <ng-container *ngIf="showMediaCreateButton">
            <button mat-button id="openMediaCreateDialogButton" class="cmn-media-list__body__content__toolbar__action"
              *cmnIfPerm="['scr:media:create:audio', 'scr:media:create:image', 'scr:media:create:video',
            'scr:media:create:pdf', 'scr:media:create:dynamic-template'];op:'OR'" (click)="openMediaCreateDialog()">
              <mat-icon>add</mat-icon>
              <span [innerHTML]="'MEDIA.IMPORT_BUTTON' | translate"></span>
            </button>
          </ng-container>
          <ng-container *ngIf="showTemplateCreateButton">
            <button mat-button id="openTemplateCreatePageButtonOnMobile"
              class="cmn-media-list__body__content__toolbar__action cmn-media-list__body__content__toolbar__action__create-page"
              *cmnIfPerm="['scr:media:create:template', 'scr:media:create:inherited-template'];op:'OR'"
              (click)="openTemplateCreatePage()">
              <mat-icon>add</mat-icon>
              <span [innerHTML]="'MEDIA.CREATE_TEMPLATE_BUTTON' | translate"></span>
            </button>
          </ng-container>
          <button class="cmn-media-list__body__content__toolbar__action__dropdown-button" *ngIf="isMobilePhone"
            mat-raised-button (click)="toggleDropdown()">
            <mat-icon *ngIf="showFormMedia; else sidebarClosed">keyboard_arrow_up</mat-icon>
            <ng-template #sidebarClosed>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-template>
          </button>
        </div>

        <div class="cmn-media-list__body__content__toolbar__dropdown-section__dropdown-content" *ngIf="isMobilePhone"
          [ngClass]="showFormMedia && isMobilePhone ? 'show' : 'not-show'">
          <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
        </div>
      </div>

      <p class="breadcrumb-media-list" *ngIf="directoryMode === DirectoryMode.TREE">{{directoryPath}}</p>

      <div class="cmn-media-list__body__content__header">
        <app-sort-select class="cmn-media-list__body__content__header__item" [availableSorts]="availableSorts"
          [selectedSort]="selectedSort" (selectionChange)="onSortSelectionChange($event)">
        </app-sort-select>
        <mat-paginator class="cmn-media-list__body__content__header__item" #paginator [pageSize]="20"
          [pageSizeOptions]="[20, 40, 100]" (page)="handlePage()">
        </mat-paginator>
      </div>
      <div class="cmn-media-list__body__content__grid" cdkDropList cdkDropListSortingDisabled
        [cdkDropListData]="mediaDataSource.data">
        <mat-tree class="cmn-media-list__body__content__grid_folder" *ngIf="directoryMode === DirectoryMode.TREE"
          [dataSource]="directoryDataSource" [treeControl]="treeControl">
          <mat-nested-tree-node *matTreeNodeDef="let node" class="cmn-media-list__body__content__grid_folder_list">
            <div *ngIf="shownDirectory(node)" (click)="onNodeClick(node)" cdkDropList class="folder-tile">
              <div class="folder-tile_content">
                <img src="/assets/images/screenlab/media-directory/icon-directory.png">
              </div>
              <div class="folder-tile_footer">
                <span *ngIf="currentDirectory.parentId == node.id; else displayName">..</span>
                <ng-template #displayName><span>{{ node.name }}</span></ng-template>
              </div>
            </div>
            <ng-container matTreeNodeOutlet></ng-container>
          </mat-nested-tree-node>
        </mat-tree>
        <div *ngFor="let droppableMedia of mediaDataSource.data" class="cmn-media-list__body__content__grid__tile">
          <div class="cmn-media-list__body__content__grid__tile__drag" (cdkDragStarted)="handleDragStart($event)"
            (cdkDragEnded)="handleDragEnded($event)" cdkDrag [cdkDragData]="droppableMedia"
            [cdkDragDisabled]="dragDisabled"
            [class.media-selected]="selectMode && droppableMedia.data === selectedMedia">
            <app-media-tile [showIncludedInScenarioIcon]="droppableMedia.includedInScenario"
              [media]="castToMedia(droppableMedia.data)"
              (click)="!dragging ? onMediaClick(castToMedia(droppableMedia.data)) : ''" [showTooltip]="!dragging">
            </app-media-tile>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterTemplate>
  <ng-container *cmnIfPerm="['scr:media:filter:unrestricted']">
    <form *ngIf="mediaFilterForm" class="cmn-media-list__body__filter" [formGroup]="mediaFilterForm">

      <!-- search -->
      <mat-form-field appearance="outline">
        <mat-label>{{ "SEARCH" | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" (keyup)="handleSearchKeyUp($event)">
      </mat-form-field>

      <!-- domains -->
      <ng-container *ngIf="showSelectDomains">
        <div *cmnIfPerm="['scr:media:filter:domains']" class="cmn-media-list__body__filter__item">
          <span class="cmn-media-list__body__filter__item__title">{{ 'DOMAIN.SELECT.LABEL' | translate }}</span>
          <searchable-select formControlName="domains" [placeholder]="'DOMAIN.SELECT.PLACEHOLDER' | translate"
            [showSelectArrow]=true [multipleSelect]=true [stickyPlaceholder]=false
            (selectionChanged)="onFilterSelectionChanged()">
          </searchable-select>
        </div>
      </ng-container>

      <!-- mediaType -->
      <div class="cmn-media-list__body__filter__item">
        <span class="cmn-media-list__body__filter__item__title">{{ 'MEDIA.MEDIA_TYPE' | translate }}</span>
        <searchable-select formControlName="mediaTypes" [placeholder]="'MEDIA.MEDIA_TYPE' | translate"
          [showSelectArrow]=true [multipleSelect]=true [stickyPlaceholder]=false
          (selectionChanged)="onFilterSelectionChanged()">
        </searchable-select>
      </div>

      <!-- orientations -->
      <div class="cmn-media-list__body__filter__item">
        <span class="cmn-media-list__body__filter__item__title">{{ 'ORIENTATION.SELECT.LABEL' | translate }}</span>
        <searchable-select formControlName="orientations" [placeholder]="'ORIENTATION.SELECT.PLACEHOLDER' | translate"
          [showSelectArrow]=true [multipleSelect]=true [stickyPlaceholder]=false
          (selectionChanged)="onFilterSelectionChanged()">
        </searchable-select>
      </div>

      <!-- tags -->
      <div *cmnIfPerm="['scr:tag:read']" class="cmn-media-list__body__filter__item">
        <span class="cmn-media-list__body__filter__item__title">{{ 'TAG.SELECT.LABEL' | translate }}</span>
        <searchable-select formControlName="tags" [placeholder]="'TAG.SELECT.PLACEHOLDER' | translate"
          [showSelectArrow]="true" [multipleSelect]="true" [stickyPlaceholder]="false"
          (selectionChanged)="onFilterSelectionChanged()">
        </searchable-select>
      </div>

      <!-- system -->
      <div *cmnIfPerm="['scr:media:filter:system']" class="cmn-media-list__body__filter__item">
        <span class="cmn-media-list__body__filter__item__title">
          {{ 'MEDIA_LIST.MY_MEDIAS.DISPLAY_MEDIA_SYSTEMS' | translate }}
        </span>
        <mat-slide-toggle formControlName="system"></mat-slide-toggle>
      </div>

      <!-- validityDate -->
      <div>
        <span class="cmn-media-list__body__filter__item__title"> {{ 'MEDIA.FIELDS.VALIDITY_PERIOD.LABEL' | translate }}</span>
        <mat-form-field class="cmn-media-list__body__filter__item__validity-date-form" >
          <mat-date-range-input [rangePicker]="picker">
            <input readonly matStartDate (click)="openDatepicker()" formControlName="validityStart"
                   [placeholder]="'MEDIA.FIELDS.VALIDITY_PERIOD.START' | translate">
            <input readonly matEndDate (click)="openDatepicker()" formControlName="validityEnd"
                   [placeholder]="'MEDIA.FIELDS.VALIDITY_PERIOD.END' | translate">
          </mat-date-range-input>
          <div class="cmn-media-list__body__filter__item__validity-date-form__actions">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix (click)="clearValidityDates()">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker (closed)="formatDate()"></mat-date-range-picker>
          </div>
        </mat-form-field>
      </div>

      <!-- directories -->
      <div *cmnIfPerm="['scr:directory:read']" class="cmn-media-list__body__filter__item">
        <span class="cmn-media-list__body__filter__item__title">
          {{ 'LOCATION' | translate }}
        </span>
        <app-directory [mediaListWidgetType]="mediaListWidgetType" (directoryFilter)="onDirectoryFilter($event)"
          (directoryId)="onDirectoryId($event)" (mediaDropped)="onMediaDropped($event)"
          (shareTreeControl)="onShareTreeControl($event)">
        </app-directory>
      </div>
    </form>
  </ng-container>
</ng-template>
